import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async addMediaObjectGallery (_, file) {
      try {
        const fd = new FormData()
        fd.append('file', file, file.name)
        const { data } = await axios.post('/media_objects', fd)
        return data['@id']
      } catch (err) {
        console.log(err) // eslint-disable-line no-console
      }
    },
    addMediaObjectAvatar (_, payload) {
      // eslint-disable-next-line no-console
      const onUploadProgress = payload.onUploadProgress || function (uploadEvent) { console.log(`Upload Progress: ${  Math.round(uploadEvent.loaded / uploadEvent.total * 100)  } %`) }
      const fd = new FormData()
      fd.append('file', payload.file, payload.file.name)
      return new Promise((resolve, reject) => {
        axios.post('/media_objects', fd, {
          onUploadProgress
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    removeMediaObjectGallery (_, mediaObjectId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/media_objects/${mediaObjectId}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    async removeMediaObjectAvatar (_, user) {
      const mediaObjectId = user.avatar['@id']
      user = {
        ...user,
        avatar: null,
        fileAvatar: null
      }
      try {
        const responseUser = await axios.put(`/users/${user.id}`, user)
        await axios.delete(mediaObjectId)
        return responseUser.data
      } catch (err) {
        console.log(err) // eslint-disable-line no-console
      }
    }
  },
  getters: {}
}