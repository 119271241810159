<template>
  <!-- Content Row -->
  <ValidationObserver v-slot="{ invalid, handleSubmit }">
    <form ref="profileForm" @submit.prevent="handleSubmit(saveProfileForm)">
      <div class="vx-row">

        <div class="vx-col md:w-1/2 w-full">
          <ValidationProvider :name="$t('administration.userform.form.input.username')" rules="required" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.username')" v-model="userLocal.username" name="username" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider :name="$t('administration.userform.form.input.firstname')" rules="required" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.firstname')" v-model="userLocal.firstname" name="firstname" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider :name="$t('administration.userform.form.input.lastname')" rules="required" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.lastname')" v-model="userLocal.lastname" name="lastname" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <ValidationProvider :name="$t('administration.userform.form.input.email')" rules="required|email" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.email')" v-model="userLocal.email" name="email" />
            <span class="error-class" v-for="error in errors" :key="error">{{ error }}</span>
          </ValidationProvider>

          <ValidationProvider :name="$t('administration.userform.form.input.password.new')" rules="password:@confirmPassword" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.password.new')" @input="isUpdatePassword" v-model="userLocal.plainPassword" name="password" type="password" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider :name="$t('administration.userform.form.input.password.confirm')" vid="confirmPassword" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('administration.userform.form.input.password.confirm')" v-model="confirmPassword" name="confirmPassword" type="password" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button button="submit" class="ml-auto mt-2" :disabled="invalid">{{ $t('administration.userform.form.input.submit') }}</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="resetProfileFrom">{{ $t('administration.userform.form.input.reset') }}</vs-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
const cloneDeep = require('lodash.clonedeep')
export default {
  name: 'ProfileForm',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userLocal: cloneDeep(this.user),
      confirmPassword: ''
    }
  },
  methods: {
    isUpdatePassword (input) {
      if (input) {
        this.userLocal.plainPassword = input
      }
    },
    saveProfileForm () {
      delete this.userLocal.avatar
      delete this.userLocal.fileAvatar
      if (this.user.id) {
        this.$emit('update:user', this.userLocal)
      }
      delete this.userLocal.plainPassword
      this.confirmPassword = ''
    },
    resetProfileFrom () {
      this.confirmPassword = ''
      this.$refs.profileForm.reset()
      this.userLocal = cloneDeep(this.user)
    }
  },
  watch: {
    'userLocal.firstname' (newFirstname) {
      this.$emit('update:user:firstname', newFirstname)
    },
    'userLocal.lastname' (newLastname) {
      this.$emit('update:user:lastname', newLastname)
    }
  }
}
</script>

<style>
.error-class {
  color: red;
  font-size: 0.7rem;
}
</style>
