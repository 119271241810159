import moduleMediaObject from './moduleMediaObject'

export default {
  created () {
    if (!moduleMediaObject.isRegistered) {
      this.$store.registerModule('mediaObject', moduleMediaObject)
      moduleMediaObject.isRegistered = true
    }
  }
}
