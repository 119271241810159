<template>
  <div id="user-edit">
    <vs-alert
      color="danger"
      :title="$t('administration.useredit.user_not_found.title')"
      :active.sync="isUserNotFound"
    >
      <span>{{
        $t("administration.useredit.user_not_found.message", {
          userId: this.user.id,
        })
      }}</span>
    </vs-alert>
    <vx-card v-if="user" class="pt-5 pb-5">
      <upload-avatar
        :user="user"
        @update:avatar="avatarUpdate"
        @uploaded:avatar="avatarUploaded"
      />
      <profile-form :user="user" @update:user="userUpdate" />
    </vx-card>
  </div>
</template>

<script>
import UploadAvatar from '@/views/apps/user/user-form/UploadAvatar'
import ProfileForm from '@/views/apps/user/user-form/ProfileForm'
import moduleUserManagementMixin from '@/store/user-management/moduleUserManagementMixin'
const cloneDeep = require('lodash.clonedeep')

export default {
  components: {
    UploadAvatar,
    ProfileForm
  },
  mixins: [moduleUserManagementMixin],
  data () {
    return {
      user: {},
      isUserNotFound: false
    }
  },
  methods: {
    async avatarUpdate (file)  {
      this.fileAvatar = file
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          this.$set(this.user, 'tempAvatar', e.target.result)
          this.$set(this.user, 'fileAvatar', this.fileAvatar)
        }
        reader.readAsDataURL(file)
      } else {
        this.$set(this.user, 'tempAvatar', null)
        this.$set(this.user, 'fileAvatar', null)
      }
    },
    async avatarUploaded () {
      this.isModified = true
    },
    async userUpdate (user) {
      if (this.user.fileAvatar) {
        const response = await this.$store.dispatch('mediaObject/addMediaObjectAvatar', {
          file: this.user.fileAvatar
        })
        this.user.avatar = response.data
      } else {
        this.user.avatar = null
        await this.$store.dispatch('updateUserInfo', this.user)
      }
      await this.$store.dispatch('userManagement/updateUser', this.user)
        .then((response) => {
          delete response.data.plainPassword
          this.$store.dispatch('updateUserInfo', response.data)
          this.$vs.notify({
            color: 'success',
            title: this.$t(
              'administration.userform.form.submit.success.notify.title'
            ),
            text: this.$t(
              'administration.userform.form.submit.success.notify.text',
              { username: user.username }
            )
          })
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t(
              'administration.userform.form.submit.error.notify.title'
            ),
            text: this.$t(
              'administration.userform.form.submit.error.notify.text',
              { error: error.response.data['hydra:description'] }
            ),
            fixed: true
          })
        })
    }
  },
  created () {
    this.user = cloneDeep(this.$store.state.AppActiveUser)
  }
}
</script>

<style scoped>
</style>
