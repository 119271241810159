<template>
  <!-- Avatar Row -->
  <div class="vx-row relative">
    <div class="vx-col w-full">
      <vs-progress class="progress-bar" v-show="progressUpload" :height="3" :percent="progressUpload" color="primary"></vs-progress>
      <div class="flex items-start flex-col sm:flex-row">
        <vs-avatar
                   :src="avatarSrc"
                   class="flex-shrink-0 mx-5 bg-primary"
                   size="70px"
                   :text="user.firstname + ' ' + user.lastname"/>
        <div>
          <p class="text-xl font-medium mb-2 mt-4 sm:mt-0"><span v-if="user.firstname">{{ user.firstname }}</span> <span v-if="user.lastname">{{ user.lastname }}</span></p>
          <input type="file" class="hidden" ref="update_avatar_input" @change="updateAvatar" name="photo" accept="image/*">
          <vs-button type="border" class="mr-4" @click="$refs.update_avatar_input.click()">{{ $t('administration.userform.avatar.button.' + (!user.fileAvatar ?'add':'update')) }}</vs-button>
          <vs-button v-show="hasAvatarId() || user.tempAvatar" type="border" color="danger" @click="confirmRemoveAvatar">{{ $t('administration.userform.avatar.button.delete') }}</vs-button>
          <span class="m-5">{{ avatarError }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {validate} from 'vee-validate'
import moduleMediaObjectMixin from '@/store/media-object/moduleMediaObjectMixin'

export default {
  name: 'UploadAvatar',
  mixins: [moduleMediaObjectMixin],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    bearer () {
      return this.$store.getters['auth/accessToken']
    },
    avatarSrc () {
      return this.user.id && this.user.fileAvatar && !this.user.tempAvatar ? `${this.user.fileAvatar}?bearer=${this.bearer}` : this.user.tempAvatar
    }
  },
  data () {
    return {
      avatarError: '',
      progressUpload: 0
    }
  },
  methods: {
    hasAvatarId () {
      return this.user && this.user.avatar && this.user.avatar.id && true
    },
    async updateAvatar (input) {
      if (input.target.files && input.target.files[0]) {
        const result = await validate(input.target.files[0], 'mimes:image/*', {name: 'photo'})

        if (!result.valid) {
          this.avatarError = result.errors[0]
          return
        }

        const hasAvatarId = this.hasAvatarId()

        if (hasAvatarId) {
          await this.$store.dispatch('mediaObject/removeMediaObjectAvatar', this.user)
          await this.$emit('update:avatar')
        }

        if (!this.user.id) {
          await this.$emit('update:avatar', input.target.files[0])
          return
        }

        try {
          await this.$emit('update:avatar', input.target.files[0])
          this.$vs.notify({
            color: 'success',
            title: this.$t(`administration.userform.avatar.${ hasAvatarId ? 'update' : 'add' }.success.notify.title`),
            text: this.$t(`administration.userform.avatar.${ hasAvatarId ? 'update' : 'add' }.success.notify.text`)
          })
        } catch (e) {
          this.$vs.notify({
            color: 'success',
            title: this.$t(`administration.userform.avatar.${ hasAvatarId ? 'update' : 'add' }.error.notify.title`),
            text: this.$t(`administration.userform.avatar.${ hasAvatarId ? 'update' : 'add' }.error.notify.text`)
          })
          console.error(e) // eslint-disable-line no-console
        }
      }
    },
    onAvatarUpload (uploadEvent) {
      this.progressUpload = Math.round(uploadEvent.loaded / uploadEvent.total * 100)
      if (this.progressUpload === 100) {
        setTimeout(this.onAvatarUploadEnded, 3000)
      }
    },
    onAvatarUploadEnded () {
      this.progressUpload = 0
    },
    confirmRemoveAvatar () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('administration.userform.avatar.delete.dialog.title'),
        text: this.$t('administration.userform.avatar.delete.dialog.text'),
        accept: this.removeAvatar,
        acceptText: this.$t('administration.userform.avatar.delete.dialog.acceptText'),
        cancelText: this.$t('administration.userform.avatar.delete.dialog.cancelText')
      })
    },
    async removeAvatar () {
      if (!this.user.id) {
        this.$emit('update:avatar')
        this.$vs.notify({
          color: 'success',
          title: this.$t('administration.userform.avatar.delete.success.notify.title'),
          text: this.$t('administration.userform.avatar.delete.success.notify.title')
        })
        return
      }

      try {
        if (this.hasAvatarId()) {
          const response = await this.$store.dispatch('mediaObject/removeMediaObjectAvatar', this.user)
          if (this.user['@id'] === this.$store.state.AppActiveUser['@id']) {
            await this.$store.dispatch('updateUserInfo', response)
          } else {
            this.$emit('update:avatar')
            this.user.avatar = null
          }
        }
        this.$emit('update:avatar')
        this.$forceUpdate()

        this.$vs.notify({
          color: 'success',
          title: this.$t('administration.userform.avatar.delete.success.notify.title'),
          text: this.$t('administration.userform.avatar.delete.success.notify.text')
        })
      } catch (e) {
        this.$vs.notify({
          color: 'danger',
          title: this.$t('administration.userform.avatar.delete.error.notify.title'),
          text: this.$t('administration.userform.avatar.delete.error.notify.text')
        })
        console.log(e) // eslint-disable-line no-console
      }
    }
  }
}
</script>

<style scoped>
.progress-bar{
  position: absolute;
  top: -37px;
  left: -6px;
  width: 101%;
}
</style>
